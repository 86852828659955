import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-introduction',
  templateUrl: './contact-introduction.component.html',
  styleUrls: ['./contact-introduction.component.scss']
})
export class ContactIntroductionComponent implements OnInit {

  title = "Share your problems with us";

  constructor() { }

  ngOnInit(): void {
  }

}
