import {Injectable} from '@angular/core';
import {TokenStorageService} from './token-storage.service';
import {GoogleCredentials, GoogleUser} from '../models/auth';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private tokenStorage: TokenStorageService) {
  }

  processGoogleCredentials(response: GoogleCredentials): void {
    const googleUser = jwtDecode<GoogleUser>(response.credential);
    this.tokenStorage.saveAuthData(response.credential, googleUser);
  }

  getLoggedInUser(): GoogleUser | null {
    return this.tokenStorage.getUser();
  }

  signOut(): void {
    this.tokenStorage.clear();
  }
}
