import { Component } from '@angular/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'camelot-webpage';

  onActivate(event) {
    window.scrollTo(0, 0); // how far to scroll on each step
  }
}
