<div class="card">
    <div class="face front-face"> <img src={{teamMember.imagePath}} alt="" class="profile">
        <div class="pt-3 text-uppercase name">{{teamMember.name}}</div>
        <div class="designation">{{teamMember.position}}</div>
        <div [ngClass]="teamMember.phone !== 'test' ? 'designation' : 'makeTransparent'">
            <img *ngIf="teamMember.phone !== 'test'" class="svgSize" src="../assets/img/svgs/phone.svg">
            {{teamMember.phone}}
        </div>
        <div [ngClass]="teamMember.email !== 'test' ? 'designation' : 'makeTransparent'">
            <img *ngIf="teamMember.email !== 'test'" class="svgSize" src="../assets/img/svgs/email.svg">
            {{teamMember.email}}
        </div>
    </div>
    <!-- <div class="face back-face"> <span class="fas fa-quote-left"></span>
        <div class="testimonial"> 
            {{teamMember.description}}    
        </div> <span class="fas fa-quote-right"></span>
    </div> -->
</div>
