export class TeamMemberData{
    constructor(
        public name:string, 
        public position: string, 
        public email: string,
        public phone: string,
        public imagePath: string,
        public description: string
        ){
    }
}