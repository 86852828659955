import { Component, OnInit, Input } from '@angular/core';
import { TeamMemberData } from '../teammember-data';

@Component({
  selector: 'app-teammember',
  templateUrl: './teammember.component.html',
  styleUrls: ['../team.component.scss']
})
export class TeammemberComponent implements OnInit {

  @Input() teamMember: TeamMemberData;
  constructor() { }

  ngOnInit(): void {
  }

}