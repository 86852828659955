import {Routes, RouterModule} from '@angular/router';

import {ContactPageComponent} from './components/contact-page/contact-page.component';
import {HomeComponent} from './components/home/home.component';
import {JoinUsPageComponent} from './components/join-us-page/join-us-page/join-us-page.component';
import {AuthComponent} from './components/auth/auth.component';
import {InternalHomeComponent} from './components/internal-home/internal-home.component';
import {authGuard} from './helpers/auth.guard';

const routes: Routes = [

  {path: '', component: HomeComponent},
  {path: 'contact', component: ContactPageComponent},
  {path: 'joinus', component: JoinUsPageComponent},
  {path: 'auth', component: AuthComponent},
  {path: 'internal', component: InternalHomeComponent, canActivate: [authGuard]},

  // otherwise redirect to home
  {path: '**', redirectTo: ''}
];

export const appRoutingModule = RouterModule.forRoot(routes);
