<section id="clients" class="clients bg-white">
  <div class="logosPlacement">
    <h1 class="heading">{{outClients}}</h1>
    <div class="">
      <div class="container">
        <div class="row">
          <div class="col">
            <img class="svgsize4" src="'../../assets/img/svgs/ridango.svg" alt="ridango-logo-white">
          </div>
          <div class="col">
            <img class="svgsize3" src="'../../assets/img/svgs/moticheck.svg" alt="moticheck-logo-white">
          </div>
          <div class="col">
            <img class="svgsize2" src="'../../assets/img/svgs/Nets_Logo_Pos_RGB.png" alt="nets-logo-white">
          </div>
          <div class="col">
            <img class="svgsize2" src="'../../assets/img/svgs/1ot.svg" alt="1ot-logo-white">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
