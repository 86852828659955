import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss']
})
export class IntroductionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  // header_variable=false;
  // @HostListener("document:scroll")
  // scrollfunction(){
  //   if(document.body.scrollTop > 0 || document.documentElement.scrollTop > 0){
  //     console.log("scroll somewhere else")
  //     this.header_variable=true;
  //   }
  //   else{
  //     this.header_variable=false;
  //     console.log("scrolled TOP")
  //   }
  // }
}
