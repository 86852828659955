<nav class="navbar navbar-dark bg-dark navbar-expand-md text-uppercase" [class.navbar-shrink]="headerVariable"
     id="mainNav">
  <div class="container">
    <div>
      <img class="icon" src="assets/img/logo/Camelot_short_white_white.png">
    </div>
    <button class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbar">
      <ul class="navbar-nav ms-auto">
        <li *ngIf="user" class="nav-item mx-0 mx-lg-1 d-flex align-items-end">
          <div class="dropdown">
            <a href="#" class="d-block link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              <img [src]="user.picture" alt="mdo" width="32" height="32" class="rounded-circle">
            </a>
            <ul class="dropdown-menu text-small" style="">
              <li><span class="dropdown-item">{{user.name}}</span></li>
              <li><span class="dropdown-item" >{{user.email}}</span></li>
              <li><hr class="dropdown-divider"></li>
              <li><button class="dropdown-item link-dark" (click)="signOut()">Sign out</button></li>
            </ul>
          </div>
        </li>
        <li class="nav-item mx-0 mx-lg-1"
            routerLinkActive="selected"
            [routerLinkActiveOptions]="{exact: true}">
          <a class="link link--elara nav-link py-3 rounded header-text" (click)="ToggleNavBar()" routerLink="/">Home</a>
        </li>
        <li class="nav-item mx-0 mx-lg-1"
            routerLinkActive="selected"
            [routerLinkActiveOptions]="{exact: true}">
          <a class="link link--elara nav-link py-3 rounded header-text" (click)="ToggleNavBar()" routerLink="/joinus">Join
            us</a>
        </li>
        <li class="nav-item mx-0 mx-lg-1"
            routerLinkActive="selected"
            [routerLinkActiveOptions]="{exact: true}">
          <a class="link link--elara nav-link py-3 rounded header-text" (click)="ToggleNavBar()" routerLink="/contact">Contact</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
