import { Component, OnInit } from '@angular/core';
import { TeamMemberData } from './teammember-data';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  oskar: TeamMemberData = new TeamMemberData(
    "Oskar Liblik", 
    "CO Owner/Software Developer",
    "oskar.liblik@camelot.ee",
    "+372 5262987",
    "../assets/img/friends/oskar.png",
    "Experienced full stack developer with a demonstrated history of working in the information technology and services industry. Skilled in Java, Agile Methodologies, Databases, and Spring Framework."
  )

  karl: TeamMemberData = new TeamMemberData(
    "Karl Lõoväli", 
    "CO Owner/Software Developer",
    "karl.loovali@camelot.ee",
    "+372 58021727",
    "../assets/img/friends/karl.png",
    "Experienced full stack developer with a demonstrated history of working in the information technology and services industry. "
  )

  sander: TeamMemberData = new TeamMemberData(
    "Sander Ruul", 
    "Software Developer",
    "test",
    "test",
    "https://images.unsplash.com/photo-1614574762522-6ac2fbba2208?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MjY2fHxtYW58ZW58MHx8MHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
    "Experienced full stack developer with a demonstrated history of working in the information technology and services industry. Skilled in Java, Agile Methodologies, Databases, and Spring Framework."
  )

  constructor() { }

  ngOnInit(): void {
  }

}
