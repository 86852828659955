import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {appRoutingModule} from './app.routing';
import {AppComponent} from './app.component';
import {IntroductionComponent} from './components/home/introduction/introduction.component';
import {ClientsComponent} from './components/home/clients/clients.component';
import {TeamComponent} from './components/contact-page/team/team.component';
import {ContactComponent} from './components/contact/contact.component';
import {ContactPageComponent} from './components/contact-page/contact-page.component';
import {TeammemberComponent} from './components/contact-page/team/teammember/teammember.component';
import {CamelotFooterComponent} from './components/camelot-footer/camelot-footer.component';
import {ServicesComponent} from './components/home/services/services.component';
import {StrugglesComponent} from './components/home/struggles/struggles.component';
import {HeaderComponent} from './components/header/header.component';
import {HomeComponent} from './components/home/home.component';
import {ContactIntroductionComponent} from './components/contact-page/contact-introduction/contact-introduction.component';
import {JoinUsPageComponent} from './components/join-us-page/join-us-page/join-us-page.component';
import {JoinUsContentComponent} from './components/join-us-content/join-us-content.component';
import {AuthComponent} from './components/auth/auth.component';
import {InternalHomeComponent} from './components/internal-home/internal-home.component';

@NgModule({
  declarations: [
    AppComponent,
    IntroductionComponent,
    ClientsComponent,
    TeamComponent,
    ContactComponent,
    TeammemberComponent,
    CamelotFooterComponent,
    ServicesComponent,
    StrugglesComponent,
    HeaderComponent,
    AuthComponent,
    HomeComponent,
    ContactIntroductionComponent,
    JoinUsPageComponent,
    JoinUsContentComponent,
    ContactPageComponent,
    AuthComponent,
    InternalHomeComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    appRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
