<section id="auth">
    <div class="container contentPlacement">
        <div class="row">
          <h1 class="title">Employee Login</h1>
        </div>
        <div class="row">
          <div id="btn-google"></div>
        </div>
    </div>
</section>
