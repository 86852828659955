import {Component, HostListener, OnInit} from '@angular/core';
import {GoogleUser} from '../../models/auth';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  headerVariable: boolean;
  isCollapsed: boolean;
  user: GoogleUser;

  constructor(private authService: AuthService, private router: Router) {
    this.headerVariable = false;
    this.isCollapsed = true;
  }

  ngOnInit(): void {
    this.user = this.authService.getLoggedInUser();
  }

  async signOut(): Promise<void> {
    this.authService.signOut();
    await this.router.navigate(['/'])
      .then(() => window.location.reload());
  }

  @HostListener('document:scroll')
  scrollFunction(): void {
    this.headerVariable = document.body.scrollTop > 0 || document.documentElement.scrollTop > 0;
  }

  ToggleNavBar(): void {
    const element: HTMLElement = document.getElementsByClassName('navbar-toggler')[0] as HTMLElement;
    if (element.getAttribute('aria-expanded') === 'true') {
      element.click();
    }
  }
}
