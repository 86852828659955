<section id="team" class=team>
<div class="container">
   <!-- <h1 class="title">Meet our people</h1>-->
    <div class="row">

      <div class="col-lg-2"></div>

        <div class="col-lg-4">
            <app-teammember
            [teamMember]="oskar"
            ></app-teammember>
        </div>
        <div class="col-lg-4">
            <app-teammember
            [teamMember]="karl"
            ></app-teammember>
        </div>
        <!--div class="col-lg-4">
            <app-teammember
            [teamMember]="sander"
            ></app-teammember>
        </div-->
    </div>
</div>
</section>
