<section id="services">
    <div class="container contentPlacement">
        <h1 class="text">How can we help you?</h1>

        <p class="subtitle">To survive in today's fast-changing technology world your business needs to be flexible and execute new features as fast as possible. Our people have worked on multiple projects that have raised the bar on what is possible. Experience shows that there is always a solution for a problem.</p>
        <div class="row">
            <div class="feature col-sm">
                <p class="smallHeader">solution</p>
                <hr>
                <p class="explanation">Every problem has a solution. We will help you find out the best solution for your business needs.</p>
            </div>
            <div class="feature col-sm">
                <p class="smallHeader">communication</p>
                <hr>
                <p class="explanation">In an agile process communication is key. After every two weeks we agree on new changes and how to implement them.</p>

            </div>
            <div class="feature col-sm">
                <p class="smallHeader">processes</p>
                <hr>
                <p class="explanation">Taking software development into our own hands. This allows you to focus on your product without needing to worry about us</p>
            </div>
        </div>
        <div class="row">
            <div class="feature col-sm">
                <p class="smallHeader">professionals</p>
                <hr>
                <p class="explanation">Our team members have a vast knowledge of software building and have built multiple products from start to finish</p>
            </div>
            <div class="feature col-sm">
                <p class="smallHeader">fast</p>
                <hr>
                <p class="explanation">Being a smaller company allows us to be fast with our clients, we can start building your product on day one.</p>
            </div>

            <div class="feature col-sm">
                <p class="smallHeader">reliable</p>
                <hr>
                <p class="explanation">Creating a product that elevates your business is our number one priority.</p>
            </div>
        </div>
    </div>
</section>
