import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-struggles',
  templateUrl: './struggles.component.html',
  styleUrls: ['./struggles.component.scss']
})
export class StrugglesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
