import {Component, NgZone, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {environment} from '../../../environments/environment';
import {GoogleCredentials} from '../../models/auth';
import {Router} from '@angular/router';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor(private authService: AuthService, private ngZone: NgZone, private router: Router) {
  }

  ngOnInit(): void {
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: this.ngZone.run(() => this.handleCredentialResponse.bind(this)),
      auto_select: false,
      cancel_on_tap_outside: true,
    });
    // @ts-ignore
    google.accounts.id.renderButton(
      document.getElementById('btn-google'),
      {theme: 'outline', size: 'large', width: '100%'}
    );
  }

  async handleCredentialResponse(response: GoogleCredentials): Promise<void> {
    this.authService.processGoogleCredentials(response);
    await this.router.navigate(['/internal'])
      .then(() => window.location.reload());
  }
}
