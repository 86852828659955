<section id="struggles">
    <div class="container contentPlacement">
        <!-- <h1 class="text">Common struggles with software</h1> -->
        <!-- <p class="subtitle">Problems with digitalization are quite common for many clients who have their own software. </p> -->
        <!-- <p class="subtitle">Digitalization problems are not uncommon</p> -->
        <!-- <p class="subtitle">Are you struggling with your software</p> -->
        <!-- <p class="subtitle">Does your company struggle with digitalization?</p> -->
        <!-- <p class="subtitle">Does your company need help with software?</p> -->
        <p class="subtitle">Having trouble with software?</p>
        <!-- <p class="subtitle">Are you having trouble with software?</p> -->
        <div class="row">
            <div class="col">
                <ul class="list-group points">
                    <!-- Siin saaks li sisse panna class="d-flex" ja li sisse 2 divvi -->
                    <li>Do you have an excellent idea that could change the world but don't know how to build it?</li>
                    <li>Has your software grown too big, adding new features cost many times more than before?</li>
                </ul>
            </div>
            <div class="col">
                <ul class="list-group points">
                    <li>Does building your own software seem scary and you do not know where to start from?</li>
                    <li>Does your software provider keep telling you that things can not be done?</li>
                </ul>
            </div>
        </div>
    </div>    
</section>