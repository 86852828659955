<footer class="camelotFooter text-center" >
  <div class="row">
      <div>
        <img class="icon" src='../../../assets/img/logo/Camelot_short_white_white.png'>
    </div>
  </div>

  <p class="footerText">
    © 2021 Camelot Technologies OÜ
  </p>
</footer>
