

<!-- <app-header></app-header> -->
<app-introduction></app-introduction>

<app-struggles></app-struggles>

<app-services></app-services>
<app-clients></app-clients>
<app-contact [showShadow]="true"></app-contact>
<!-- <app-camelot-footer></app-camelot-footer>  -->
