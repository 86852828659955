import { Component } from '@angular/core';
import {TokenStorageService} from '../../services/token-storage.service';
import {AuthService} from '../../services/auth.service';
import {GoogleUser} from '../../models/auth';

@Component({
  selector: 'app-internal-home',
  templateUrl: './internal-home.component.html',
  styleUrls: ['./internal-home.component.scss']
})
export class InternalHomeComponent {
  user: GoogleUser;
  constructor(private authService: AuthService) {
    this.user = this.authService.getLoggedInUser();
  }
}
