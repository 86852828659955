
<head>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
</head>
<body id="page-top" class="d-flex flex-column min-vh-100">
    <div class="d-flex flex-column min-vh-100">
        <header>
            <app-header></app-header>            
        </header>
        <main class="flex-fill">
            <router-outlet (activate)="onActivate($event)"></router-outlet>
        </main>
        <footer>
            <app-camelot-footer></app-camelot-footer> 
        </footer>
    </div>
</body>