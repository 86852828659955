import {Injectable} from '@angular/core';
import {GoogleCredentials, GoogleUser} from '../models/auth';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() {
  }

  public clear(): void {
    localStorage.clear();
  }

  saveAuthData(googleJwt: string, googleUser: GoogleUser): void {
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(TOKEN_KEY);

    localStorage.setItem(USER_KEY, JSON.stringify(googleUser));
    localStorage.setItem(TOKEN_KEY, googleJwt);
  }

  getToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  }

  getUser(): GoogleUser | null {
    const userStr = localStorage.getItem(USER_KEY);
    return userStr ? JSON.parse(userStr) : null;
  }
}
