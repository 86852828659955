<section id="home">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="homeHeaders">
                    <h1 class="title">Building software that matters</h1>
                    <p class="subtitle">We create software meant for your business needs.</p>
                </div>
            </div>
            <div class="col alignCenter">
                <img class="homeImage" src='../../assets/img/working.jpg'>
            </div>
        </div>
    </div>
</section>
