<section id="joinus">
    <div class="masthead text-white text-center">
        <div class="container d-flex align-items-center flex-column">
            <!-- Masthead Avatar Image-->
            <img class="masthead-avatar mb-5" src="../../../assets/img/svgs/avataaars.svg" alt="..." />
            <!-- Masthead Heading-->
            <h1 class="masthead-heading text-uppercase mb-0">JOIN OUR RANKS</h1>
            <!-- Icon Divider-->
            <div class="divider-custom divider-light">
                <div class="divider-custom-line"></div>
                <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                <div class="divider-custom-line"></div>
            </div>
            <!-- Masthead Subheading-->
            <p class="masthead-subheading font-weight-light mb-0">Software Developers - Web Designer - Team Leads</p>
        </div>
    </div>
    <div class="container">
        <div class="contentPlacement">
            <div class="pointsSection">
                <h2>Who are we?</h2>
                <p class="text">
                    We are a group of experienced software craftsmen who wish to make an impact.
                    We believe we are a company that looks after our people, we think that relationships between people are very important and getting to know your coworkers is the foundation of an effective team.
                    In order to do so, we have quite many events to make sure people know each other and get along.
                    We value people who can communicate and help others when they do not have all the answers.
                </p>
               <!-- <p class="text">
                    In order to keep the company going, we must provide as good products to our clients as humanly possible.
                    We use agile software development methodologies and programming languages suited for that specific product.
                    We also have weekly technology talks and workshops where we discuss ideas and learn about different technologies and how to write better code.
                    We also support taking part in different online courses or seminars to learn more about new technologies.
                                        -->
                <p class="text">

            </p>
            </div>

            <div class="pointsSection">
                <h2>Who are we looking for?</h2>
                <div class="text">
                    <ul class="text">
                        <li>Willing to learn new skills and do tasks that you haven't done before</li>
                        <li>Be self-organizing</li>
                        <li>At least 2 years of programming experience</li>
                        <li>Solid understanding of version control</li>
                        <li>You care about code quality, readability and maintainability</li>
                        <li>You take pride in what you do and welcome feedback</li>
                        <li>You over your code with tests</li>
                     <!--    <li>Having a degree in computer science is a plus, but not a must.</li> -->
                    </ul>
                </div>
            </div>

            <div class="pointsSection">
                <h2>What do we offer</h2>
                <ul class="text">
                    <li>Competitive salary</li>
                    <li>One month salary as bonus at the start of new year</li>
                    <li>Select an extra bonus between different options(extra week paid holiday, new phone, a new computer and so on.)</li>
                    <li>Free day on your birthday</li>
                    <li>Free day when your child starts first grade</li>
                    <li>Free day when your child finishes high school</li>
                    <li>3 days off on your wedding</li>
                    <li>100€ compensation for glasses per year</li>
                    <li>30€ compensation for internet per month</li>
                    <li>Possibility to do Pair programming</li>
                    <li>Work from where you want</li>
                <!--    <li>Mentoring program, we will assign you a mentor and create a personal plan based on your interests meant to elevate your career</li> -->
                    <li>No bureaucracy</li>
                    <li>Take part of our team events</li>
                    <li>Being part of interesting projects</li>
                </ul>

            </div>
        </div>
    </div>

    </section>
